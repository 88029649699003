import { render, staticRenderFns } from "./processTemplate.vue?vue&type=template&id=ba7b588a&scoped=true&"
import script from "./processTemplate.vue?vue&type=script&lang=js&"
export * from "./processTemplate.vue?vue&type=script&lang=js&"
import style0 from "./processTemplate.vue?vue&type=style&index=0&id=ba7b588a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba7b588a",
  null
  
)

export default component.exports